define("ember-mdi/components/md-icon", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-mdi/icons"], function (_exports, _component, _templateFactory, _component2, _icons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <svg
    class="md-icon md-icon-{{@icon}} {{if @spin "md-icon-animation-spin"}}"
    width={{this.size}}
    height={{this.size}}
    viewBox="0 0 24 24"
    transform={{this.transform}}
    role={{this.role}}
    ...attributes
    >
    {{#if @title}}
      <title>{{@title}}</title>
    {{/if}}
    <path
      d={{this.d}}
      fill={{@fill}}
      stroke={{@stroke}}
      stroke-width={{@strokeWidth}}
      stroke-linecap={{@strokeLinecap}}
      stroke-linejoin={{@strokeLinejoin}}
      stroke-dasharray={{@strokeDasharray}}
      stroke-dashoffset={{@strokeDashoffset}}
      stroke-opacity={{@strokeOpacity}}
    />
  </svg>
  
  */
  {
    "id": "rR/hjcNC",
    "block": "[[[11,\"svg\"],[16,0,[29,[\"md-icon md-icon-\",[30,1],\" \",[52,[30,2],\"md-icon-animation-spin\"]]]],[16,\"width\",[30,0,[\"size\"]]],[16,\"height\",[30,0,[\"size\"]]],[24,\"viewBox\",\"0 0 24 24\"],[16,\"transform\",[30,0,[\"transform\"]]],[16,\"role\",[30,0,[\"role\"]]],[17,3],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[10,\"title\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"path\"],[15,\"d\",[30,0,[\"d\"]]],[15,\"fill\",[30,5]],[15,\"stroke\",[30,6]],[15,\"stroke-width\",[30,7]],[15,\"stroke-linecap\",[30,8]],[15,\"stroke-linejoin\",[30,9]],[15,\"stroke-dasharray\",[30,10]],[15,\"stroke-dashoffset\",[30,11]],[15,\"stroke-opacity\",[30,12]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@icon\",\"@spin\",\"&attrs\",\"@title\",\"@fill\",\"@stroke\",\"@strokeWidth\",\"@strokeLinecap\",\"@strokeLinejoin\",\"@strokeDasharray\",\"@strokeDashoffset\",\"@strokeOpacity\"],false,[\"if\"]]",
    "moduleName": "ember-mdi/components/md-icon.hbs",
    "isStrictMode": false
  });
  class MdIconComponent extends _component2.default {
    get d() {
      return _icons.default[this.args.icon];
    }
    get size() {
      return this.args.size || 24;
    }
    get role() {
      return this.args.role || 'img';
    }
    get transform() {
      const rotate = this.args.rotate;
      const flipH = this.args.flipH;
      const flipV = this.args.flipV;
      let transform = '';
      if (rotate && rotate !== '0') {
        transform += `rotate(${rotate})`;
      }
      if (flipH && flipV) {
        transform += 'scale(-1,-1)';
      } else if (flipH) {
        transform += 'scale(-1,1)';
      } else if (flipV) {
        transform += 'scale(1,-1)';
      }
      if (transform === '') {
        transform = null;
      }
      return transform;
    }
  }
  _exports.default = MdIconComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MdIconComponent);
});